import { merge } from 'lodash'

import messages from './translation'
import orkanetMessages from './translation.orkanet'

if (process.env.VUE_APP_ENVIRONMENT_NAME === 'orkanet') {
    merge(messages, orkanetMessages)
}

export { messages }
